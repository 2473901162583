// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-call-for-speakers-js": () => import("./../src/pages/call-for-speakers.js" /* webpackChunkName: "component---src-pages-call-for-speakers-js" */),
  "component---src-pages-certificate-js": () => import("./../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-confirmation-js": () => import("./../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-program-js": () => import("./../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-schedule-js": () => import("./../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-sponsors-js": () => import("./../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-tickets-js": () => import("./../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

